<template>
  <div class="w-full h-full pt-5">
    <h1 class="text-xl text-left font-extrabold pl-4">Employees</h1>
    <div class="mx-3 mt-6">
      <Tab :tabs="['Salaried']" border :active-tab="topCurrentTab" @currentTab="topCurrentTab = $event" />
    </div>
    <div class="mx-3 mt-6">
      <div class="flex justify-between">
        <Tab :tabs="['Active', 'Inactive']" border :active-tab="currentTab" @currentTab="currentTab = $event" />
        <div v-if="Object.keys(metaData).length > 0 && !loading && !loadingTable" class="font-bold mr-8">
          <span class="text-ceruleanBlue text-4xl font-extrabold mr-4">{{
            metaData.total
          }}</span>{{ currentTab === "Active" ? "Active" : "Inactive" }} Employees
        </div>
      </div>
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div>
          <Card class="text-jet p-5">
            <div v-if="currentTab === 'Active'" class="flex mb-6" style="justify-content: flex-start">
              <p>
                An overview of employees and their payroll status can be found
                here.
              </p>
            </div>
            <div v-if="currentTab === 'Inactive'" class="flex flex-col w-full mb-8">
              <div class="
            flex
            w-full
            rounded
            text-blue text-sm
            font-bold
            px-4
            py-3
          " style="
          background: rgba(244, 213, 176, 0.15); border: 1px solid #E99323;" role="alert">
                <Icon icon-name="tooltip" size="l" class="w-8 h-8 mr-2" />
                <div class="flex flex-col tracking-wide ml-2">
                  <p class="mb-2">
                    Your employees will be listed here when: </p>
                  <p class="mb-1">
                    o The effective payroll date is not specified.
                  </p>
                  <p class="mb-1">
                    o Pay frequency has not been assigned.
                  </p>
                  <p class="mb-1">
                    o Annual gross figures have not been updated.
                  </p>
                  <p class="mb-1">
                    o Bank information is missing or not updated.
                  </p>

                </div>
              </div>
            </div>
            <template v-slot:footer>
              <card-footer v-if="!showError" @sortType="handleSort($event)" @searchResult="handleSearch($event)"
                class="mt-5" @actionModal="viewFilterTable($event)" />
            </template>
          </Card>
        </div>
        <div class="pt-3">

          <sTable :headers="headers" v-if="!showError" :items="tableData" style="width:100%; height:auto" class="w-full"
            aria-label="employee table" :loading="loadingTable" :has-checkbox="false" :pagination-list="metaData"
            @page="handlePage($event)" @itemsPerPage="handleItemsPerPage($event)" page-sync has-number>
            <template v-slot:item="{ item }">
              <div v-if="item.photo">
                <img class="" :src="item.data.photo" v-if="item.data.photo" alt="user photo"
                  style="height: 30px; width: 30px; border-radius: 5px" />
                <div style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2" v-else>
                  {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                </div>
              </div>
              <div class="font-bold" style="color: #525252" v-else-if="item.fname">
                {{ item.data.fname }} {{ item.data.lname }} <br />
                <span class="text-romanSilver text-xs">
                  {{
                    item.data.userDesignation
                      ? item.data.userDesignation.name
                      : "-"
                  }}
                </span>
              </div>
              <span v-else-if="item.orgFunction">
                {{
                  item.data.orgFunction ? item.data.orgFunction.name : "-",
                }}
              </span>

              <span v-else-if="item.payType" class="capitalize">
                {{ item.data.payType ? item.data.payType : "-" }}
              </span>
              <span v-else-if="item.payFrequency" class="capitalize">
                {{
                  item.data.payFrequency
                    ? formatPayFreqency(item.data.payFrequency)
                    : "-"
                }}
              </span>
              <span v-else-if="item.employmentType">{{
                !item.data.employmentType ? "-" : item.data.employmentType
              }}</span>
              <span v-else-if="item.grossPay" class="grosspay">
                {{
                  !item.data.grossPay
                    ? "-"
                    : "₦" + item.data.grossPay.toLocaleString()
                }}
              </span>
              <span v-else-if="item.id">
                <div class="cursor-pointer" v-if="!item.data.editable"
                  @click="viewEmployee(item.data.userId, currentTab)">
                  <icon icon-name="icon-eye" class-name="text-blueCrayola" size="xs" />
                </div>
              </span>
            </template>
          </sTable>
          <div v-else>
            <error-page />
          </div>

          <FilterTable v-if="filterTable" :filter-data="filterArray" @close="closeFilter($event)"
            @getParams="searchFilter($event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";

export default {
  name: "LocationAndTax",
  components: {
    Tab,
    Card,
    FilterTable,
    STable,
    CardFooter,
    ErrorPage,
  },
  data() {
    return {
      openModal: false,
      currentTab: "Active",
      topCurrentTab: "Salaried",
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      sortType: null,
      tableData: [],
      metaData: {},
      showError: false,
      taxData: [],
      loading: true,
      loadingTable: false,
      headers: [
        { title: "", value: "photo", width: 6 },
        { title: "Full Name", value: "fname" },
        { title: "Function", value: "orgFunction" },
        { title: "Employment Type", value: "employmentType" },
        { title: "Pay Type", value: "payType" },
        { title: "Pay Frequency", value: "payFrequency" },
        { title: "Annual Gross", value: "grossPay" },
        { title: "", value: "id", image: true },
      ],
      filterArray: [
        { header: "Function(s)", value: "functionId", optionsBox: [] },
        { header: "Employment Type", value: "employmentType", optionsBox: [] },
        { header: "Pay Type(s)", value: "levelId", optionsBox: [] },
        { header: "Pay Frequency(s)", value: "locationId", optionsBox: [] },
        // { header: "Designation(s)", value: "designationId", optionsBox: [] },
      ],
      filterTable: false,
    };
  },
  methods: {
    formatPayFreqency(frequency) {
      if (frequency === "twice_monthly") {
        return "Twice Monthly";
      }
      if (frequency === "weekly") {
        return "Four Times Monthly";
      }
      return frequency;
    },

    viewFilterTable(value) {
      if (value) {
        this.filterTable = true;
      }
    },

    async viewEmployee(userId, tab) {
      if (tab === "Active") {
        try {
          await this.$handlePrivilege(
            "eligibleEmployee",
            `viewActiveEmployeesPayHistory`
          );
          this.$router.push({ name: "viewEmployee", params: { id: userId }, query: { slug: 'active' } });
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      } else {
        try {
          await this.$handlePrivilege(
            "eligibleEmployee",
            `viewInactiveEmployeesPayHistory`
          );
          this.$router.push({ name: "viewEmployee", params: { id: userId }, query: 'inactive' });
        } catch (error) {
          this.$toasted.error(
            "You do not have permission to perform this task",
            {
              duration: 5000,
            }
          );
        }
      }
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      const currentTab = this.currentTab.toLowerCase();
      this.$_getEmployeePayroll(
        `${currentTab}?${sort}${search}${pageNumber}${itemPage}`
      ).then((response) => {
        this.tableData = response.data.data.employees;
        this.metaData = response.data.data.meta;
        this.loadingTable = false;
      });
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    handleSearch(searchWord) {
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },

    handleSort(sortType) {
      this.sortType = sortType;
      this.queryUpdate();
    },

    async viewActive() {
      this.loading = true;
      try {
        await this.$handlePrivilege("eligibleEmployee", "viewActiveEmployeeRegister");
        this.showError = false;
        this.$_getEmployeePayroll("active").then((response) => {
          this.tableData = response.data.data.employees;
          this.metaData = response.data.data.meta;
          this.loading = false;
        });
      } catch (error) {
        this.showError = true;
        this.loading = false;
      }
    },

    async viewInactive() {
      this.loading = true;
      try {
        await this.$handlePrivilege("eligibleEmployee", "viewInactiveEmployeeRegister");
        this.showError = false;
        this.$_getEmployeePayroll("inactive").then((response) => {
          this.tableData = response.data.data.employees;
          this.metaData = response.data.data.meta;
          this.loading = false;
        });
      } catch (error) {
        this.showError = true;
        this.loading = false;
      }
    },

    getAllFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.map((functionVal) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Function(s)") {
              filter.optionsBox.push({
                id: functionVal.id,
                name: functionVal.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLocations() {
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Pay Frequency(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Pay Type(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getEmploymentType() {
      this.$_getHumanarEmploymentTypes().then(({ data }) => {
        data?.result?.map((outlet) => this.filterArray.forEach((filter) => {
            if (filter.header === "Employment Type") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          }))
      });
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },

    searchFilter(params) {
      const currentTab = this.currentTab.toLowerCase();

      this.$_getEmployeePayroll(`${currentTab}?${params}`).then((response) => {
        this.tableData = response.data.data.employees;
        this.metaData = response.data.data.meta;
        this.loadingTable = false;
      });

      this.filterTable = false;
    },
  },
  computed: {},
  watch: {
    currentTab(value) {
      if (value === "Inactive") {
        this.viewInactive();
      } else if (value === "Active") {
        this.viewActive();
      }
    },
  },
  created() {
    this.viewActive();
    this.getEmploymentType();
    this.getAllDesignations();
    this.getAllFunctions();
    this.getAllLevels();
    this.getAllLocations();
  },
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}

.chat-img {
  height: 80px;
  margin-right: 12px;
  width: 80px;
  object-fit: cover;
  box-shadow: 0px 1px 2px rgba(45, 49, 66, 0.06), 0px 1px 3px rgba(8, 7, 8, 0.1);
  border-radius: 5px;
}

.fcard {
  padding: 12px;
  /* height: 58px; */
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 16px;
  height: 16px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}
</style>
